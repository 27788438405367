<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button v-if="canAccess(65)" size="sm" variant="primary" @click="isActiveModal = true" class="mr-1">
                            <span class="text-nowrap">
                                <feather-icon icon="PlusIcon" /> Agregar Unidad
                            </span>
                        </b-button>

                        <b-button v-if="canAccess(62)" size="sm" variant="outline-primary" @click="isActiveModalAddMaintenance = true">
                            <span class="text-nowrap">
                                <feather-icon icon="PlusIcon" /> Solicitar Mantenimiento
                            </span>
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input size="sm" v-model="searchQuery"
                                    placeholder="Buscar por identificador, marca, modelo o placas" />
                                <b-input-group-append>
                                    <b-button size="sm" variant="primary">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <b-button @click="getTrucks" size="sm" class="ml-1" variant="outline-primary">
                                <feather-icon icon="RefreshCwIcon" />
                            </b-button>
                        </div>

                    </b-col>
                </b-row>
            </div>

            <!-- Truck List -->
            <b-table ref="refTrucksListTable" class="position-relative text-small small" :items="listTrucks" responsive
                :fields="columns" primary-key="id" show-empty empty-text="No se encontraron resultados"
                :busy.sync="loading" stacked="md" small striped>
                <!-- Empty -->
                <template slot="empty">
                    <div v-if="loading" class="d-flex justify-content-center mb-1">
                        <b-spinner style="width: 3rem; height: 3rem" class="mr-1" label="Loading..."
                            variant="primary" />
                    </div>
                </template>

                <template #cell(identificador)="data">
                    #{{ data.item.identificador }}
                </template>

                <template #cell(vencimiento_Poliza)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.vencimiento_Poliza) }}</span>
                </template>

                <template #cell(ultimo_Servicio)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.ultimo_Servicio) }}</span>
                </template>

                <template #cell(empresa_Id)="data">
                    {{ data.item.conexiones.nombre || 'Sin empresa' }}
                </template>

                <!-- Sección de detalles expandidos -->
                <template #row-details="row">
                    <div class="p-1 bg-light">
                        <h4 class="mb-3_">Detalles completos de la unidad # {{ row.item.identificador }}</h4>

                        <b-row>
                            <!-- Columna izquierda -->
                            <b-col cols="12" md="6">
                                <b-list-group>
                                    <b-list-group-item><strong>Marca:</strong> {{ row.item.marca }}</b-list-group-item>
                                    <b-list-group-item><strong>Modelo:</strong> {{ row.item.modelo }}</b-list-group-item>
                                    <b-list-group-item><strong>Placas:</strong> {{ row.item.placas }}</b-list-group-item>
                                    <b-list-group-item><strong>Último servicio:</strong> {{ formatDate(row.item.ultimo_Servicio) }}</b-list-group-item>
                                    <b-list-group-item><strong>Odómetro:</strong> {{ row.item.odometro }}</b-list-group-item>
                                    <!-- <b-list-group-item><strong>Próximo servicio:</strong> {{ calculateNextService(row.item.ultimo_Servicio) }}</b-list-group-item> -->
                                </b-list-group>
                            </b-col>

                            <!-- Columna derecha -->
                            <b-col cols="12" md="6">
                                <b-card class="py-0">
                                    <b-button v-if="canAccess(62)" @click="addMaintenance(row.item)" class="float-right" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary" size="sm">
                                        <feather-icon icon="PlusIcon" /> Solicitar
                                    </b-button>
                                    <app-timeline v-for="(group, index) in row.item.maintenances" :key="index" class="mt-3">
                                        <app-timeline-item :variant="getVariantMaintenance(group.type)">
                                            <div
                                                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                                <h6>{{ group.identifier }}</h6>
                                                <small class="text-muted text-capitalize">{{ group.updated_at_human }}</small>
                                            </div>
                                            <p>{{ group.description }}</p>
                                            <p>
                                                <div class="badge-wrapper mr-1">
                                                    <b-badge v-for="tag in group.tags" :key="tag" pill :variant="`${resolveTagVariant(tag)}`" class="text-capitalize mr-1">
                                                        {{ tag }}
                                                    </b-badge>
                                                </div>
                                            </p>
                                            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                                <!-- 1st Col -->
                                                <div class="mb-1 mb-sm-0">
                                                    <span class="text-muted mb-50 d-block">Solicitado Por</span>
                                                    <span class="text-capitalize"> {{group.created_by}}</span>
                                                </div>
                                                <!-- 2nd Col -->
                                                <div class="mb-1 mb-sm-0">
                                                    <span class="text-muted mb-50 d-block">Solicitado el</span>
                                                    <span class="text-capitalize">{{formatDate(group.created_at)}}</span>
                                                </div>
                                                <!-- 3rd Col -->
                                                <div>
                                                    <span class="text-muted mb-50 d-block">Mecanico</span>
                                                    <span>{{group.mechanic.name}}</span>
                                                </div>
                                            </div>
                                            <p></p>
                                        </app-timeline-item>
                                    </app-timeline>                                    

                                    <div v-if="row.item.maintenances.length <= 0" class="no-results" :class="{ 'show': true }">
                                        <h5>No hay mantenimientos encontrados</h5>

                                        <b-button @click="toggleDetails(row.item)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="outline-primary" size="sm">
                                            <feather-icon icon="ChevronUpIcon" /> Ocultar Detalles
                                        </b-button>
                                    </div>
                                </b-card>
                            </b-col>
                        </b-row>

                        <b-button v-if="row.item.maintenances.length > 0" @click="toggleDetails(row.item)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary" size="sm">
                            <feather-icon icon="ChevronUpIcon" /> Ocultar Detalles
                        </b-button>
                    </div>
                </template>

                <!-- Actions -->
                <template #cell(actions)="data">
                    <div class="demo-inline-spacing">

                        <b-button @click="toggleDetails(data.item)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary" class="btn-icon" size="sm"
                            v-b-tooltip.hover.right="`${data.item._showDetails ? 'Ocultar' : 'Mostrar'} mantenimientos`">
                            <feather-icon icon="ToolIcon" />
                        </b-button>

                        <b-button v-if="data.item.imagenes.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="secondary" class="btn-icon" size="sm" @click="openModalImages(data.item)"
                            v-b-tooltip.hover.right="`Ver imagenes`">
                            <feather-icon icon="EyeIcon" />
                        </b-button>

                        <b-button v-if="data.item.activo && canAccess(66)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning"
                            class="btn-icon" size="sm" @click="editTruck(data.item)" v-b-tooltip.hover.right="`Editar`">
                            <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button v-if="data.item.id != 1 && canAccess(67)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            :variant="data.item.activo ? 'danger' : 'dark'" class="btn-icon" size="sm"
                            @click="deleteTruck(data.item)"
                            v-b-tooltip.hover.right="`${data.item.activo ? 'Desactivar' : 'Activar'}`">
                            <feather-icon :icon="data.item.activo ? 'SlashIcon' : 'CheckSquareIcon'" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </b-card>

        <modal-add-maintenance :active.sync="isActiveModalAddMaintenance" :trucks="trucks" :truck_id="truck_id" @add-maintenance="reloadTrucks"/>

        <!-- Modal Trucks -->
        <b-modal ref="infoModalRef" id="modalCreateUpdateCamiones" :title="titleModal" no-close-on-backdrop hide-footer
            @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal" size="md">

            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <!-- BODY -->
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <b-form ref="formRef" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <b-row>

                            <b-col cols="12">
                                <b-form-group label="Empresa" label-for="company_id">
                                    <validation-provider #default="{ errors }" name="empresa" rules="required">
                                        <v-select v-model="truck.empresa_Id" :options="companies" class="select-size-sm"
                                            :reduce="(val) => val.idEmpresa"
                                            :clearable="false"
                                            :searchable="true"
                                            input-id="empresa"
                                            label="nombre"
                                            placeholder="Selecciona una opción"
                                            :state="errors.length > 0 ? false : null"
                                        />   
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Tipo Unidad" label-for="type">
                                    <validation-provider #default="{ errors }" name="tipo" rules="required">
                                        <v-select v-model="truck.tipo" :options="['CAMION', 'MAQUINA', 'PERSONAL']" class="select-size-sm"
                                            id="type"
                                            :clearable="false"
                                            :searchable="true"
                                            placeholder="Selecciona una opción"
                                            :state="errors.length > 0 ? false : null"
                                        />   
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Tipo Combustible" label-for="fuel">
                                    <validation-provider #default="{ errors }" name="combustible" rules="required">
                                        <v-select v-model="truck.combustible" :options="['GASOLINA', 'DIESEL']" class="select-size-sm"
                                            id="fuel"
                                            :clearable="false"
                                            :searchable="true"
                                            placeholder="Selecciona una opción"
                                            :state="errors.length > 0 ? false : null"
                                        />   
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                            <b-col cols="6">
                                <b-form-group label="Identificador" label-for="identifier">
                                    <validation-provider #default="{ errors }" name="identificador" rules="required">
                                        <b-form-input size="sm" id="identifier" v-model="truck.identificador"
                                            :state="errors.length > 0 ? false : null" name="identifier"
                                            placeholder="Identificador de la unidad" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Placas" label-for="license_plate">
                                    <validation-provider #default="{ errors }" name="año" rules="required">
                                        <b-form-input size="sm" id="license_plate" v-model="truck.placas"
                                            :state="errors.length > 0 ? false : null" name="license_plate"
                                            placeholder="Número de placas" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label="Modelo" label-for="model">
                                    <b-form-input size="sm" id="model" v-model="truck.modelo" name="model"
                                        placeholder="Modelo" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label="Marca" label-for="brand">
                                    <b-form-input size="sm" id="brand" v-model="truck.marca" name="brand"
                                        placeholder="Marca" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label="Año" label-for="anio">
                                    <b-form-input size="sm" id="anio" v-model="truck.anio" name="anio"
                                        placeholder="Año" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Serie" label-for="serie">
                                    <b-form-input size="sm" id="serie" v-model="truck.serie" name="serie"
                                        placeholder="Núm. de Serie" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Base" label-for="base">
                                    <b-form-input size="sm" id="base" v-model="truck.base" name="num_Seguro"
                                        placeholder="Base" />
                                </b-form-group>
                            </b-col>

                            <!-- <b-col cols="6">
                                <b-form-group label="Vencimiento de Poliza" label-for="vencimiento_Poliza">                                
                                    <flat-pickr v-model="truck.vencimiento_Poliza" id="vencimiento_Poliza" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Último Servicio" label-for="vencimiento_Poliza">                                
                                    <flat-pickr v-model="truck.ultimo_Servicio" id="vencimiento_Poliza" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-form-group>
                            </b-col>                       -->
                        </b-row>

                        <!-- Optional files -->
                        <!-- <b-row>
                            <b-col v-for="(image, index) in truck.Imagenes" :key="index" cols="12">
                                <div class="d-flex justify-content-between mt-1">
                                    <label for="login-password">Imagen {{ index + 1 }} </label>
                                    <b-link v-if="index === 0" @click="addImage">
                                        <small>+ Agregar</small>
                                    </b-link>

                                    <b-link variant="text-danger" v-else @click="truck.Imagenes.splice(index, 1)">
                                        <small class="text-red text-danger">- Quitar</small>
                                    </b-link>
                                </div>                                
                                <b-form-file v-model="image.imagen" :id="`file-small-${index}`" size="sm"
                                    placeholder="Elija un archivo o suéltelo aquí..."
                                    drop-placeholder="Suelta el archivo aquí..."
                                    label-data-browse="Elegir"
                                    accept="image/*"/>
                            </b-col>
                        </b-row> -->


                        <!-- Form Actions -->
                        <div class="text-right mt-2">
                            <b-button size="sm" class="mr-2" :disabled="loading"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                                @click="closeModal">
                                Cancelar
                            </b-button>

                            <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary" type="submit">
                                Guardar
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-overlay>
        </b-modal>

        <b-modal ref="infoModalImagesRef" id="modalImagesCamiones"
            :title="`Camión: ${truck.identificador} - ${truck.placas}`" no-close-on-backdrop hide-footer
            @cancel="isActiveModalImages = false" @hidden="closeModalImages" :visible="isActiveModalImages" size="md">

            <!-- <b-carousel id="carousel-example-generic" controls indicators>
                <b-carousel-slide v-for="(item, index) in truck.imagenes" :img-src="getImageUrl(item.imagen)" :key="index" class="w-100" />
            </b-carousel> -->
            <b-row>
                <b-col cols="12" class="mb-1" v-for="(item, index) in truck.imagenes" :key="index">
                    <b-img fluid-grow :src="getImageUrl(item.imagen)" />
                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BOverlay,
    BFormCheckbox,
    BFormFile,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BImg,
    BListGroup,
    BListGroupItem,
    BCollapse,
    BAvatarGroup,

} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, alphaNum, email, password } from "@validations";
import ModalAddMaintenance from '@core/components/app-maintenances/ModalAddMaintenance.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import "animate.css";
import draggable from 'vuedraggable'
import store from '@/store';

import WebCamionesProvider from "@/providers/WebCamiones";
import { FeatherIcon } from 'vue-feather-icons';
const WebCamionesResource = new WebCamionesProvider();

if (process.env.MIX_FALLBACK_LOCALE === "es") {
    localize('es');
}

export default {
    name: 'TrucksList',
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BSpinner,
        BTooltip,
        VBTooltip,
        VBToggle,
        vSelect,
        BFormCheckbox,
        BSidebar,
        draggable,
        flatPickr,
        BFormFile,
        BCarousel,
        BCarouselSlide,
        BContainer,
        BImg,
        BListGroup,
        BListGroupItem,
        BCollapse,
        BAvatarGroup,

        //
        AppTimeline,
        AppTimelineItem,
        ModalAddMaintenance,

        //
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            password,
            hideSidebar: false,
            trucks: [],
            selectedFile: null,
            truck: {
                usuario_Id: "string",
                empresa_Id: store.getters['auth/getConexionId'],
                identificador: "",
                marca: "",
                anio: "",
                modelo: "",
                tipo: "CAMION",
                placas: "",
                nombre_Aseguradora: "",
                serie: "",
                base: "",
                num_Seguro: "",
                vencimiento_Poliza: null,
                num_Frecuencia_Servicio: 0,
                ultimo_Servicio: null,
                odometro: 0,
                combustible: "GASOLINA",
                activo: true,
                fecha_Alta: new Date(),
                fecha_Actualizacion: new Date(),
                Imagenes: [
                    {
                        imagen: null
                    }
                ],
            },
            columns: [
                {
                    key: "identificador",
                    label: 'Identificador',
                },
                {
                    key: "marca",
                    label: 'Marca',
                },
                {
                    key: 'modelo',
                    label: 'Modelo',
                },
                {
                    key: "anio",
                    label: 'Año',
                },
                {
                    key: "placas",
                    label: 'Placas',
                },
                {
                    key: "serie",
                    label: 'Serie',
                },
                {
                    key: "base",
                    label: 'Base',
                },
                // {
                //     key: "vencimiento_Poliza",
                //     label: 'Vencimiento Poliza',
                // },
                {
                    key: "odometro",
                    label: 'Odometro',
                },
                {
                    key: "ultimo_Servicio",
                    label: 'Último Servicio',
                },
                {
                    key: "estatus",
                    label: 'Estatus',
                },
                {
                    key: "actions",
                    label: 'Acciones'
                },
                {
                    key: "empresa_Id",
                    label: 'Empresa',
                }
            ],
            sortBy: "id",
            sortDesc: true,
            searchQuery: null,
            from: 0,
            to: 0,
            loading: false,
            isEdit: false,
            isActiveModal: false,
            isDetail: false,
            titleModal: "Alta de Camiones",
            roles: [],
            isActiveModalImages: false,
            isActiveModalAddMaintenance: false,
            truck_id: null,
        };
    },
    computed: {
        listTrucks() {
            let result = this.searchQuery ? this.trucks.filter(o =>
                o.identificador?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                o.marca?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                o.modelo?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                o.placas.toLowerCase().includes(this.searchQuery.toLowerCase())
            ) : this.trucks;
            return result
        },
        companies() {
            return store.getters['auth/getConnections']
        }
    },
    watch: {

    },
    async mounted() {
        await this.getTrucks()
    },
    methods: {
        //Sync Nube
        async syncTruck(truck) {
            // this.loading = true
            // try {
                


            //     const { data } = axios.post(`${process.env.VUE_APP_API_PRICES}/api/client/trucks`, payload, {
            //         headers: {
            //             Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
            //             Accept: 'application/json',
            //         },

            //     })

            // } catch (e) {
            //     this.handleResponseErrors(e)
            // } finally {
            //     this.loading = false
            // }
        },
        async reloadTrucks(data) {
            await this.getTrucks()
            if (this.truck_id) {
                const item = this.listTrucks.find(item => item.id == this.truck_id)
                item.estatus = 'En mantenimiento'
                item.odometro = data.odometer
                this.toggleDetails(item)
                this.$set(item, '_showDetails', true)
                this.truck_id = null
            }
        },
        addMaintenance(item) {
            this.truck_id = item.id
            this.isActiveModalAddMaintenance = true
        },
        resolveTagVariant(tag) {
            if (tag === 'Pendiente') return 'light-primary'
            if (tag === 'Completado') return 'light-success'
            if (tag === 'Preventivo') return 'light-success'
            if (tag === 'Correctivo') return 'light-danger'
            if (tag === 'Eliminado') return 'danger'
            if (tag === 'update') return 'info'
            if (tag === 'Media') return 'warning'
            if (tag === 'Alta') return 'danger'
            if (tag === 'Baja') return 'success'
            return 'primary'
        },
        getVariantMaintenance(type) {
            const variants = {
                'Preventivo': 'success',
                'Correctivo': 'danger',
            }
            return variants[type] || 'light-primary';
        },
        getVariant(tag) {
            // const finded = tags.value.find(item => item.id == tag)
            // return finded ? finded.color : 'info'
            const variants = {
                'Media': 'light-warning',
                'Alta': 'light-danger',
                'Baja': 'light-success'
            }
            return variants[tag] || 'light-primary';
        },
        async toggleDetails(item) {

            // Cierra todas las filas primero si quieres solo una abierta a la vez
            this.listTrucks.forEach(i => {
                if (i !== item) this.$set(i, '_showDetails', false)
            })

            this.$set(item, '_showDetails', !item._showDetails)

            if (item._showDetails) {

                item.maintenances = []
                this.loading = true

                //Cargar mantenimientos
                const queryParts = [
                    `truck.source_id:${item.id};company_id:${store.getters['auth/getConexionId']}`,
                ]

                const query = {
                    search: queryParts.join(';'),
                    searchFields: 'truck.source_id:=;company_id:=',
                    searchJoin: 'and',
                    with: 'truck;mechanic',
                    orderBy: 'created_at',
                    sortedBy: 'desc',
                }

                try {
                    const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/maintenances`, {
                        headers: {
                            Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
                            Accept: 'application/json',
                        },
                        params: query
                    })

                    if (data.success) {
                        item.maintenances = data.data.map(task => {
                            return {
                                ...task,
                                title: task.truck.label,
                                dueDate: task.created_at,
                                description: task.description,
                                assignee: {
                                    fullName: task.mechanic.name,
                                    avatar: null,
                                },
                                tags: [task.status, task.type, task.tag],
                                isCompleted: task.is_completed,
                                isDeleted: task.is_deleted,
                                isImportant: task.is_important,
                                odometer: task.odometer,
                            }
                        })
                    } else {
                        this.danger(data.message)
                    }

                } catch (error) {
                    this.handleResponseErrors(error)
                } finally {
                    this.loading = false
                }
            }
        },
        getMaintenanceBadge(type) {
            const types = {
                preventivo: 'success',
                correctivo: 'warning',
                emergencia: 'danger'
            }
            return types[type.toLowerCase()] || 'secondary'
        },
        calculateNextService(lastServiceDate) {
            // Tu lógica para calcular próximo servicio
            const nextDate = new Date(lastServiceDate)
            nextDate.setMonth(nextDate.getMonth() + 6)
            return this.formatDate(nextDate)
        },
        addImage() {
            this.truck.Imagenes.push({
                imagen: null,
            })
        },
        openModalImages(item) {
            this.truck = { ...item }
            this.isActiveModalImages = true
        },
        closeModalImages() {
            this.isActiveModalImages = false
        },
        closeModal() {
            this.isActiveModal = false
            this.isEdit = false
            this.truck = {
                usuario_Id: "string",
                empresa_Id: 0,
                identificador: null,
                marca: "",
                anio: "",
                modelo: "",
                tipo: "CAMION",
                placas: "",
                serie: "",
                base: "",
                nombre_Aseguradora: "",
                num_Seguro: "",
                vencimiento_Poliza: null,
                num_Frecuencia_Servicio: 0,
                ultimo_Servicio: null,
                odometro: 0,
                combustible: "GASOLINA",
                activo: true,
                fecha_Alta: new Date(),
                fecha_Actualizacion: new Date(),
                Imagenes: [
                    {
                        imagen: null
                    }
                ],
            }
            this.titleModal = 'Alta de Camión'
        },
        async onSubmit() {


            const formData = new FormData();
            delete this.truck.Imagenes;

            // for (const propiedad in this.truck) {
            //     if (this.truck[propiedad] && (propiedad !== 'Imagenes' && propiedad !== 'imagenes')){
            //         formData.append(propiedad, this.truck[propiedad]);
            //     }                
            // }

            // // Verificar si hay imágenes para agregar al FormData
            // if (this.truck.Imagenes.length > 0 && this.truck.Imagenes[0].imagen !== null) {
            //     // Agregar cada imagen al FormData
            //     this.truck.Imagenes.forEach(function(image, index) {
            //         if (image.imagen) {
            //             // formData.append(`imagens[${index}]`, image.imagen);
            //             formData.append('imagens', image.imagen);
            //         }
            //     });
            // }
            // formData.append('empresa_Id', store.getters['auth/getConexionId'])

            // formData.delete('imagenes');
            // formData.delete('Imagenes');


            try {

                this.loading = true
                const { data } = this.isEdit ? await WebCamionesResource.update(this.truck) : await WebCamionesResource.store(this.truck);
                this.loading = false
                if (data.isSuccesful) {
                    this.success(data.message);
                    await this.getTrucks()
                    this.isActiveModal = false
                    this.closeModal()

                } else {
                    this.danger(data.message);
                }
            } catch (e) {
                this.handleResponseErrors(e)
            } finally
            {
                this.loading = false
            }
        },
        editTruck(item) {
            item.Imagenes = [{
                imagen: null
            }]

            this.isEdit = true
            this.truck = { ...item }
            this.isActiveModal = true
            this.titleModal = 'Editar Camión'
        },
        async getTrucks() {
            this.loading = true;
            try {
                const { data } = await WebCamionesResource.index({});
                if (data.isSuccesful) {
                    this.trucks = data.data.filter(t => t.id != 1).map(item => {
                        item.label = `#${item.identificador} - ${item.marca} - ${item.modelo} - ${item.anio}`
                        return item
                    })
                } else {
                    this.danger(data.message)
                }
            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            } finally {
                this.loading = false
            }
        },
        deleteTruck(item) {
            console.log(item)
            this.$swal({
                title: `¿Desea ${item.activo ? 'desactivar' : 'activar'} el camión?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: `Continuar`,
                cancelButtonText: `Cancelr`,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await WebCamionesResource.destroy(item.id)
                        this.loading = false

                        if (data.isSuccesful) {
                            this.success(data.message)
                            await this.getTrucks()
                        } else {
                            this.$swal.showValidationMessage(
                                `Request failed: ${data.message}`
                            );
                        }
                    } catch (e) {
                        this.$swal.showValidationMessage(
                            `Request failed: ${e}`
                        );
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
    },
    watch: {
        isActiveModalAddMaintenance(value) {
            // if (!value) {
            //     this.truck_id = null
            // }
        }
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.custom-file-button+label {
    display: none;
}

.carousel-image {
    width: 300px;
    /* Establecer el ancho deseado */
    height: 200px;
    /* Establecer la altura deseada */
    object-fit: cover;
    /* Para que la imagen cubra el tamaño especificado */
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.vehicle-group {
    transition: all 0.3s;
    border-left: 3px solid transparent;
    padding-left: 8px;

    &:hover {
        background-color: #f8f9fa;
    }

    &.active-group {
        border-left-color: #7367f0;
        background-color: #f8f9fa;
    }
}

.media {
    padding: 1rem;
    border-radius: 0.5rem;
    transition: all 0.3s;
    background-color: #f8f9fa;
}

.media:hover {
    background-color: #e9ecef;
    transform: translateX(5px);
}
</style>
